import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NoteIcon from '@mui/icons-material/Note';
import FlagIcon from '@mui/icons-material/Flag';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LockIcon from '@mui/icons-material/Lock';
import SyncIcon from '@mui/icons-material/Sync';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CategoryIcon from '@mui/icons-material/Category';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import CodeIcon from '@mui/icons-material/Code';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import InfoIcon from '@mui/icons-material/Info';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CampaignIcon from '@mui/icons-material/Campaign';

const icons = {
    PersonIcon: <PersonIcon />,
    DashboardIcon: <DashboardIcon />,
    MonetizationOnIcon: <MonetizationOnIcon />,
    NoteIcon: <NoteIcon />,
    FlagIcon: <FlagIcon />,
    LocalMallIcon: <LocalMallIcon />,
    LockIcon: <LockIcon />,
    SyncIcon: <SyncIcon />,
    SmartphoneIcon: <SmartphoneIcon />,
    SettingsIcon: <SettingsIcon />,
    AssignmentIndIcon: <AssignmentIndIcon />,
    CategoryIcon: <CategoryIcon />,
    LocationOnIcon: <LocationOnIcon />,
    BusinessIcon: <BusinessIcon />,
    CodeIcon: <CodeIcon />,
    MiscellaneousServicesIcon: <MiscellaneousServicesIcon />,
    InfoIcon: <InfoIcon />,
    AssessmentIcon: <AssessmentIcon />,
    EventAvailableIcon: <EventAvailableIcon />,
    CampaignOutlinedIcon: <CampaignIcon />
};

export default icons;
