const Columns = {
    CLIENTES: [
        'Código',
        'Razão social',
        'CNPJ',
        'Status',
        'Cód. vendedor',
        'Filial',
        'Atividade',
        'Nome fantasia',
        'Info'
    ],
    CAMPANHA: [
        'Filial',
        'Código',
        'Descrição',
        // 'Tipo modalidade',
        'Data inicial',
        'Data final',
        'Mix mínimo',
        'Valor mínimo',
        'Valor máximo',
        'Volume mínimo',
        'Volume máximo',
        'Qtd. máx. bonificada',
        'Permite acumular desconto',
        'Status',
        'Requisito',
        'Benefício'
    ],

    CAMPANHA_BENEFICIO: [
        'Código',
        'Tipo',
        'Código do tipo',
        'Descrição do tipo',
        'Quantidade bonificada',
        'Aplica bonificação automática',
        'Percentual desconto',
        'Aplica desconto automático',
        'Status'
    ],

    CAMPANHA_REQUISITO: [
        'Código',
        'Tipo',
        'Código do tipo',
        'Descrição do tipo',
        'Quantidade mínima',
        'Quantidade máxima',
        // 'Peso mínimo',
        // 'Peso máximo',
        'Valor mínimo',
        'Valor máximo',
        'Item obrigatório',
        'Status'
    ],
    VENDEDORES: [
        'Código',
        'Nome',
        'Supervisor',
        'Gerente',
        'Tipo',
        'Saldo verba',
        'Status',
        'Info'
    ],
    EMPRESAS: [
        'Cód.',
        'CNPJ',
        'Razão social',
        'Nome fantasia',
        'Qtd. Licença',
        'Status',
        'Ação'
    ],
    INTEGRACOES: [
        'Cód.',
        'Empresa matriz',
        'Empresa filial',
        'URL Api',
        'URL Ecommerce',
        'Status',
        'Ação'
    ],
    ELEMENTOS: [
        'Cód.',
        'Parâmetro 1',
        'Parâmetro 2',
        'Parâmetro 3',
        'Código externo',
        'Tipo',
        'Integrador',
        'Ação'
    ],
    DISPOSITIVOS: [
        'Id',
        'Vendedor',
        'Marca',
        'Modelo',
        'Versão - App',
        'Mac',
        'Utiliza horário?',
        'Status',
        'Ação'
    ],
    VERSAO: ['Cód.', 'Versão', 'Obrigatório', 'Observação', 'Ação'],
    USUARIO: [
        'Id',
        'Nome',
        'E-mail',
        'Telefone',
        'Usuário',
        'Perfil',
        'Status',
        'Ação'
    ],
    PERFIL: ['Id', 'Perfil', 'Tipo do Perfil', 'Status', 'Ação'],
    HISTORICO_COMPRAS: [
        'Id',
        'Filial',
        'Cliente',
        'Nome fantasia',
        'Vendedor',
        'Pedido ERP',
        'N° da nota',
        'Valor pedido',
        'Valor faturado',
        'Data do pedido',
        'Data do faturamento',
        'Status',
        'Info'
    ],
    NOTA_DETALHADA: [
        'Descrição',
        'Valor Unitário',
        'Desconto',
        'Pedido qtd.',
        'NFS qtd.',
        'Total pedido',
        'Total faturado',
        'Status'
    ],
    META: [
        'Filial',
        'Vendedor',
        'Descricao',
        'Qtd. vendida',
        'Total em peso',
        'Objetivo',
        'Total vendido',
        'Tendência',
        '% Atingido',
        'Info'
    ],
    TITULO_FINANCEIRO: [
        'Doc.',
        'Cliente',
        'Valor original',
        'Parcela',
        'Valor titulo',
        'Multa/Juros',
        'Valor atual',
        'Status pgto.',
        'Status venc.',
        'Forma pgto.',
        'Emissão',
        'Vencimento',
        'Linha digitável',
        'Compartilhar'
    ],
    GRAFICO_TITULOS: [
        'Clientes',
        'Valor original',
        'Valor titulo',
        'Multa/Juros',
        'Valor atual'
    ],
    PRODUTO: [
        'Grupo',
        'Subgrupo',
        'Id',
        'Filial',
        'Código',
        'Descrição',
        'Unidade',
        'Status',
        'Info'
    ],
    PEDIDOS_ANDAMENTO: [
        'Id nuvem',
        'Filial',
        'Vendedor',
        'Cód. cliente',
        'Pedido ERP',
        'Valor total',
        'Tipo pedido',
        'Data emissão',
        'Data recebimento',
        'Data sinc.',
        'Status',
        'Origem',
        'Info'
    ],
    PEDIDOS_COORDENADAS: [
        'Filial',
        'Vendedor',
        'Pedidos c/c',
        'Pedidos s/c',
        'Total de pedidos',
        'Positivação',
        'Ticket médio',
        'Valor total',
        'Info',
        'Mapa'
    ],
    PEDIDOS_COORDENADAS_CID: [
        'IBGE',
        'Estado',
        'Cidade',
        'Região',
        'Qtd. pedidos',
        'Valor'
    ],
    PEDIDOS_COORDENADAS_EST: [
        'Sigla',
        'Estado',
        'Região',
        'Qtd. pedidos',
        'Valor'
    ],
    LISTAR_SENHA: [
        'Id',
        'Gerada por',
        'Solicitado por',
        'MAC',
        'Motivo',
        'Data',
        'Observação'
    ],
    LIBERADOS_POR_SENHA: [
        'Pedido AFV',
        'Liberado por',
        'Solicitado por',
        'Senha utilizada',
        'Motivo',
        'Observação',
        'Distância do cliente',
        'Data emissão',
        'Info'
    ],
    LISTAR_SENHA_PEDIDO: [
        'Pedido AFV / WEB:',
        'Liberado por',
        'Solicitado por',
        'Senha utilizada',
        'Motivo',
        'Observação',
        'Distância do cliente',
        'Data de Emissão'
    ],
    LIBERADOS_POR_SENHA_DETALHADO: [
        'Id',
        'Foto (url)',
        'Descrição',
        'Unidade',
        'Unitário',
        'IPI',
        'ST',
        'Frete',
        'Seguro',
        'Verba',
        'Desconto',
        'Qtd.',
        'Total'
    ],
    PEDIDO_DETALHADO: [
        'Ordem',
        'Foto',
        'Descrição',
        'Unidade',
        'Qtd.',
        'Unitário',
        'IPI',
        'ST',
        'Frete',
        'Seguro',
        'Verba',
        'Desconto',
        'Total'
    ],
    PEDIDOS_VENDEDOR: [
        'Filial',
        'Pedido AFV / WEB',
        'Cliente',
        'Data e hora',
        'Valor',
        'Detalhes'
    ],
    GRUPOS: ['Cód.', 'Empresa', 'Descrição', 'Status', 'Ação'],
    RELATORIO: [
        'Cód.',
        'Grupo',
        'Imagem',
        'Título',
        'Slug',
        'Gráfico',
        'Status',
        'Usuário cad.',
        'Cadastro',
        'Usuário alt.',
        'Alteração',
        'SQL',
        'DataKey',
        'Ação'
    ],
    TIPO_GRAFICO: ['Cód.', 'Descrição', 'Status', 'Ação'],
    ORDEM_VISITA: ['Ordem', 'Cliente', 'Data', 'Endereço'],
    MARGEM_MARKUP: ['Descrição', 'Custo', 'Rentabilidade', 'Margem', 'Markup'],
    VISITA: [
        'Marcar',
        'ID nuvem',
        'Razão social',
        'ID retaguarda',
        'Filial',
        'Prioridade'
    ],
    DETALHAMENTO_AFV: [
        'Filial',
        'Cód. ERP',
        'Vendedor',
        'Valor pedido',
        'Faturado',
        'Data do pedido',
        'Data do faturamento',
        'Status'
    ],
    SERVICO_DAS_EMPRESAS: ['Cód.', 'Empresa', 'Status'],
    DASHBOARD: [
        'Nome',
        'Nº pedidos',
        'Valor',
        '% Sobre total',
        '% Acumulado',
        'Peso líquido',
        'Peso bruto'
    ],
    PRAZO_PAGAMENTO: [
        'Código',
        'Descrição',
        'Valor mínimo',
        'Variação',
        'Status'
    ],
    TABELA_PRECO: [
        'Código',
        'Descrição',
        'Data inicial',
        'Data final',
        'Gera verba',
        'Filial'
    ],
    FORMAS_PAGAMENTO: [
        'Código',
        'Descrição',
        'Valor mínimo',
        'Situação',
        'Status'
    ]
};

export default Columns;
